import React, { useEffect, useState, useRef } from "react";
import notFoundImg from "../assets/img-not-found.jpg";
import "../App.css";
import {
  loadImagesData,
  rateImage,
  getUserBatch,
  updateLastIdViewProfile,
} from "../api";
import axios from "axios";
import { loadImages } from "./imagesPreloader";
import timerImg from "../assets/timer.png";
let reservelastIdToPre = 0;
let intervalId;
let lastIdWhenClickedOnPrevious = 0;
let source;
let imageBaseUrl = "https://s3.eu-central-1.wasabisys.com/instaua/images/";
export let batchLimit = 50;
export const InstagramViewImages = ({
  useData,
  batchStartingId,
  setBatchStartingId,
  batchArrRef,
  batchLoadedByLastId,
}) => {
  const [pBtnActive, setPBtnActive] = useState(false);
  const [rBtnActive, setRBtnActive] = useState(false);
  const [timer, setTimer] = useState(false);
  const [loading, setLoading] = useState(false);
  const [instagramData, setInstagramData] = useState();
  const [showingRecord, setShowingRecord] = useState(1);
  const [isPreReqRecived, setIsPreReqRecived] = useState(false);
  const [isTimerDisable, setisTimerDisable] = useState(true);
  const [isFirstTime, setIsFirstTime] = useState(true);
  const [isloading, setIsLoading] = useState(null);
  const pauseRef = useRef(null);
  const userData = useRef(null);
  const disableTimerRef = useRef(true);
  const batchCountRef = useRef(0);
  const [totalDone, setTotalDone] = useState(useData.done);
  const [imagesLoading, setImagesLoading] = useState(false);
  console.log('imagesLoading: ', imagesLoading);
  const [batchLoading, setBatchLoading] = useState(false);
  // Next
  let stopNext = instagramData?.id == useData.lastRowId;
  useEffect(() => {
    reservelastIdToPre = useData.last_id;
  }, []);

  const getData = async () => {
    // setIsPreReqRecived(false);
    if (stopNext) {
      clearInterval(intervalId);
      setTimer(false);
    } else {
      source = axios.CancelToken.source();
      let lastId = Number(userData.current.id) + 1;
      let isUpdateLastId = useData.last_id == reservelastIdToPre;
      if (isUpdateLastId) {
        updateLastIdViewProfile(
          Number(userData.current.id),
          useData.id,
          userData.current.accountId
        );
      }

      const imagesUrlArr = [];
      // const done = 0;
      let response;
      const data = batchArrRef.current.find(
        (user) => lastId <= Number(user.id)
      );
      if (data) {
        response = {
          accountId: data.user_id,
          imagesData: imagesUrlArr,
          username: data.username,
          id: Number(data.id),
        };
      } else {
        setIsLoading("Downloading images...");
      }

      if (response) {
        if (reservelastIdToPre == useData.lastRowId) {
          clearInterval(intervalId);
          setTimer(false);
        }
        //
        if (!pauseRef.current && !(reservelastIdToPre == useData.lastRowId)) {
          if (useData.last_id == reservelastIdToPre) {
            useData.last_id += 1;
          }
          reservelastIdToPre += 1;

          setInstagramData(response);
          userData.current = response;
          setRBtnActive(false);
          if (reservelastIdToPre > lastIdWhenClickedOnPrevious) {
            setShowingRecord((pre) => (pre += 1));
          }
        }
        if (!pauseRef.current && reservelastIdToPre == useData.lastRowId) {
          setInstagramData(response);
          userData.current = response;
          setRBtnActive(false);
        }
      }
      setIsPreReqRecived((pre) => pre + 1);
    }
  };
  useEffect(() => {
    if (!pBtnActive && timer && isPreReqRecived && !isTimerDisable) {
      intervalId = setTimeout(() => {
        getData();
      }, [parseInt(`${useData.change_time}000`)]);
    } else {
      clearInterval(intervalId);
    }
  }, [pBtnActive, timer, isPreReqRecived, isTimerDisable]);
  // Start click
  const handleOnClick = async () => {
    source = axios.CancelToken.source();
    lastIdWhenClickedOnPrevious = 0;
    setLoading(true);
    setIsFirstTime(false);
    let imagesUrlArr = [];
    // let done = 0;
    const data = batchArrRef.current[0];
    const response = {
      accountId: data.user_id,
      // totalDone: done,
      imagesData: imagesUrlArr,
      username: data.username,
      id: Number(data.id),
    };
    updateLastIdViewProfile(
      Number(useData.last_id),
      useData.id,
      response.accountId
    );
    if (reservelastIdToPre != useData.lastRowId) {
      useData.last_id += 1;
      reservelastIdToPre += 1;
    }

    // setIsPreReqRecived(true);
    setIsPreReqRecived(1);

    setLoading(false);
    setInstagramData(response);
    userData.current = response;

    if (reservelastIdToPre == useData.lastRowId) {
      clearInterval(intervalId);
      setTimer(false);
    } else {
      setTimer(true);
    }
  };

  useEffect(() => {
    const handleKeywordAction = (event) => {
      if (event.key === "ArrowDown") {
        event.preventDefault();
        handleClickOnR(true);
        // handlePause();
      }
      if (event.key === "ArrowUp") {
        event.preventDefault();
        handleClickOnR();
      }
      if (event.key === "ArrowLeft") {
        event.preventDefault();
        getPreviousUser();
      }
      if (event.key === "ArrowRight") {
        event.preventDefault();
        if (disableTimerRef.current) {
          if (!(stopNext || imagesLoading)) {
            getNextUser();
          }
        }
      }
      if (event.key === "t") {
        toggleTimmer();
      }
    };
    window.addEventListener("keydown", handleKeywordAction);
    return () => {
      window.removeEventListener("keydown", handleKeywordAction);
    };
  }, [pBtnActive, rBtnActive]);
  function handlePause() {
    setPBtnActive((pre) => {
      if (
        pre &&
        reservelastIdToPre != useData.lastRowId &&
        useData.last_id != reservelastIdToPre
      ) {
        if (useData.last_id == reservelastIdToPre) {
          useData.last_id += 1;
        }
        reservelastIdToPre += 1;
      }
      pauseRef.current = !pre;
      // only cancel when paused
      !pre && source.cancel("Request canceled");
      return !pre;
    });
    setTimer(true);
  }
  // Pre
  const getPreviousUser = async () => {
    source = axios.CancelToken.source();
    setTimer(false);
    lastIdWhenClickedOnPrevious = useData.last_id;
    if (
      useData.last_id == reservelastIdToPre &&
      reservelastIdToPre != useData.lastRowId
    ) {
      reservelastIdToPre -= 2;
    } else {
      reservelastIdToPre -= 1;
    }
    const lastId = Number(userData.current.id) - 1;
    const imagesUrlArr = [];
    // const done = 0;
    let response;
    const data = batchArrRef.current
      .slice()
      .reverse()
      .find((user) => lastId >= Number(user.id));
    if (data) {
      response = {
        accountId: data.user_id,
        // totalDone: done,
        imagesData: imagesUrlArr,
        username: data.username,
        id: Number(data.id),
      };
    } else {
      response = await loadImagesData(
        lastId,
        useData.id,
        false,
        source,
        useData.where_filter,
        true,
        instagramData?.accountId
      );
    }

    await loadImages([{ user_id: response.accountId }]);
    setInstagramData(response);
    userData.current = response;
    setTimer(true);
    setRBtnActive(false);
    setIsPreReqRecived(true);
  };

  async function getNextUser() {
    source = axios.CancelToken.source();
    let lastId = Number(userData.current.id) + 1;
    let updateLastId = false;
    if (useData.last_id <= lastId) {
      useData.last_id = lastId;
      updateLastId = true;
    }
    reservelastIdToPre = lastId;
    if (updateLastId) {
      updateLastIdViewProfile(
        Number(userData.current.id),
        useData.id,
        userData.current.accountId
      );
    }
    const imagesUrlArr = [];
    let response;
    const data = batchArrRef.current.find((user) => lastId <= Number(user.id));
    if (data) {
      response = {
        accountId: data.user_id,
        imagesData: imagesUrlArr,
        username: data.username,
        id: Number(data.id),
      };

      if (reservelastIdToPre > lastIdWhenClickedOnPrevious) {
        setShowingRecord((pre) => (pre += 1));
      }
      setInstagramData(response);
      userData.current = response;
      setRBtnActive(false);
    } else {
      setIsLoading("Downloading images...");
    }
  }

  const getProfilesShowed = () => {
    const value = (useData.last_id / useData.count) * 100;
    return parseInt(value);
  };
  const handleClickOnR = async (table2SaveFlag) => {
    setRBtnActive(true);
    try {
      const response = await rateImage(
        userData.current?.username,
        userData.current?.accountId,
        useData.id,
        useData.input_save,
        table2SaveFlag
      );
      if (response.done) {
        setTotalDone(response.done);
      }
    } catch (error) {}
  };

  // load next when previous batch upto 3....
  useEffect(() => {
    if (
      batchArrRef.current[3 + batchCountRef.current * batchLimit]?.username ==
      userData.current?.username
    ) {
      console.log(
        "5 + batchCountRef.current * batchLimit: ",
        5 + batchCountRef.current * batchLimit
      );
      setBatchLoading(true);
      getUserBatch(
        batchLoadedByLastId.current,
        batchLimit,
        useData.where_filter
      )
        .then(async (res) => {
          await loadImages(res);
          setIsLoading(null);
          setBatchStartingId(useData.last_id);
          batchArrRef.current = [...batchArrRef.current, ...res];
          batchLoadedByLastId.current = Number(res[batchLimit - 1].id) + 1;
          batchCountRef.current += 1;
          setBatchLoading(false);
        })
        .catch((err) => {
          console.error("err: ", err);
          setBatchLoading(false);
        });
    }
  }, [instagramData]);

  function toggleTimmer() {
    setisTimerDisable((pre) => {
      disableTimerRef.current = !pre;
      return !pre;
    });
  }

  const [imageUrls, setImageUrls] = useState([]);

  useEffect(() => {
    const fetchImagesFromCache = async () => {
      const promises = [];
      setImagesLoading(true);
      for (let index = 0; index < 12; index++) {
        let accountId = instagramData.accountId;
        let imageUrl = imageBaseUrl + accountId + "_" + index + ".jpeg";

        const promise = caches.match(imageUrl).then((response) => {
          if (response) {
            return response.blob().then((blob) => URL.createObjectURL(blob));
          } else {
            return notFoundImg;
          }
        });

        promises.push(promise);
      }

      // Wait for all promises to resolve
      Promise.all(promises).then((blobUrls) => {
        setImagesLoading(false);
        setImageUrls(blobUrls);
      });
    };
    if (instagramData) {
      fetchImagesFromCache();
    } else {
      console.log("no data");
    }
  }, [instagramData]);

  return isloading && batchLoading ? (
    <div className="header">{isloading}</div>
  ) : (
    <>
      {isFirstTime ? (
        <div className="header">
          <button onClick={handleOnClick} type="button" className="btn-primary">
            Start
          </button>
        </div>
      ) : loading ? (
        <div className="header">loading....</div>
      ) : (
        <div className="parent-wrapper">
          <div className="menu-main">
            <div className="menu1">
              <button
                disabled={instagramData?.id <= 1 ? true : false}
                onClick={getPreviousUser}
                className={`menu-btn ${
                  instagramData?.id <= 1 ? "cur-disable" : ""
                }`}
              >
                {"<"}
              </button>
              {isTimerDisable ? (
                <button
                  onClick={getNextUser}
                  disabled={stopNext || imagesLoading}
                  className={`menu-btn ${
                    stopNext || imagesLoading ? "cur-disable" : ""
                  }`}
                >
                  {">"}
                </button>
              ) : (
                <button
                  onClick={handlePause}
                  className={`menu-btn ${pBtnActive && "btn-active"}`}
                >
                  P
                </button>
              )}
            </div>
            <div className="menu2">
              <div className="menu2Btn-wrapper">
                <button
                  onClick={toggleTimmer}
                  className={`menu-btn ${isTimerDisable && "btn-active"}`}
                >
                  <img className="timerImg" src={timerImg} alt="" />
                </button>
                <button
                  onClick={handleClickOnR}
                  className={`menu-btn ${rBtnActive && "btn-active"}`}
                >
                  R
                </button>
              </div>

              <div>Profiles showed: {showingRecord}</div>
              <div>%: {getProfilesShowed()}</div>
              <div>ID: {instagramData?.accountId}</div>
              <div>DB: {instagramData?.id}</div>
              <div>Total done: {totalDone}</div>
            </div>
          </div>

          <div className="img-section">
            <div className="row-header">
              <div className="row">
                {imageUrls.map((imageUrl, index) => {
                  return (
                    <div
                      key={index}
                      className={`img-block ${"image-" + (index + 1)}`}
                    >
                      <img
                        decoding="async"
                        key={index}
                        src={imageUrl}
                        className="img-styled"
                        alt=""
                      />

                      {index + 1 === 12 && (
                        <div className="menu-main">
                          <div>
                            <div>Profiles showed: {showingRecord}</div>
                            <div>%: {getProfilesShowed()}</div>
                            <div>ID: {instagramData?.accountId}</div>
                            <div>DB: {instagramData?.id}</div>
                            <div>Total done: {totalDone}</div>
                          </div>
                          <div>
                            <div className="menu1">
                              <button
                                disabled={instagramData?.id <= 1 ? true : false}
                                onClick={getPreviousUser}
                                className={`menu-btn ${
                                  instagramData?.id <= 1 ? "cur-disable" : ""
                                }`}
                              >
                                {"<"}
                              </button>
                              {isTimerDisable ? (
                                <button
                                  onClick={getNextUser || imagesLoading}
                                  disabled={stopNext || imagesLoading}
                                  className={`menu-btn ${
                                    stopNext || imagesLoading
                                      ? "cur-disable"
                                      : ""
                                  }`}
                                >
                                  {">"}
                                </button>
                              ) : (
                                <button
                                  onClick={handlePause}
                                  className={`menu-btn ${
                                    pBtnActive && "btn-active"
                                  }`}
                                >
                                  P
                                </button>
                              )}
                            </div>
                            <div className="menu2">
                              <div className="menu2Btn-wrapper">
                                <button
                                  onClick={toggleTimmer}
                                  className={`menu-btn ${
                                    isTimerDisable && "btn-active"
                                  }`}
                                >
                                  <img
                                    className="timerImg"
                                    src={timerImg}
                                    alt=""
                                  />
                                </button>
                                <button
                                  onClick={handleClickOnR}
                                  className={`menu-btn ${
                                    rBtnActive && "btn-active"
                                  }`}
                                >
                                  R
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};
