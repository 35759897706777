import React, { useState, useEffect, useRef } from "react";
import { InstagramViewImages } from "./components";
import { checkUserAvailble } from "./api";
import { ErrorBoundry } from "./components/ErrorBoundry";
import { getUserBatch } from "./api";
import { loadImages } from "./components/imagesPreloader";
import { batchLimit } from "./components";
function App() {
  // if (window.location.hostname !== "localhost") {
  //   console.log = () => {};
  // }
  const slug = window.location.pathname.replace("/", "");
  const [isUserAvailble, setIsUserAvailble] = useState(false);
  const [batchStartingId, setBatchStartingId] = useState("");
  const [isloading, setIsLoading] = useState("Loading...");
  const batchArrRef = useRef([]);
  const batchLoadedByLastId = useRef(null);
  useEffect(() => {
    if (slug) {
      setIsLoading("Loading...");
      checkUserAvailble(slug)
        .then((res) => {
          setIsLoading("Downloading images...");
          let data = res;
          setIsUserAvailble({ ...data, count: Number(data.count) });
          setBatchStartingId(res.last_id);
          getUserBatch(res.last_id, batchLimit, res.where_filter)
            .then(async (data) => {
              batchArrRef.current = data;
              batchLoadedByLastId.current = Number(data[batchLimit - 1].id) + 1;
              await loadImages(data);
              setIsLoading(null);
            })
            .catch((err) => {
              setIsLoading(null);
            });
        })
        .catch((err) => {
          setIsLoading(null);
        });
    } else {
      setIsLoading(null);
    }
  }, [slug]);
  return isloading ? (
    <div className="header">{isloading}</div>
  ) : !slug ? (
    <>
      <ErrorBoundry isNotFound={true} />
    </>
  ) : !isUserAvailble ? (
    <ErrorBoundry isNotFound={false} />
  ) : (
    <>
      <InstagramViewImages
        batchStartingId={batchStartingId}
        setBatchStartingId={setBatchStartingId}
        useData={isUserAvailble}
        batchArrRef={batchArrRef}
        batchLoadedByLastId={batchLoadedByLastId}
      />
    </>
  );
}

export default App;
