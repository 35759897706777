import axios from "axios";
import Swal from "sweetalert2";

let backendUrl = process.env.REACT_APP_BACKEND_URL;
export const loadImagesData = async (
  lastId,
  userId,
  isUpdateLastId,
  cancelTokenSource,
  isWhere,
  isPre,
  accountId
) => {
  try {
    const response = await axios.get(backendUrl + "/api/getImages", {
      params: {
        lastId: lastId,
        userId: userId,
        isUpdateLastId: isUpdateLastId,
        isWhere: isWhere,
        isPre: isPre,
        // account_Id:accountId
      },
      cancelToken: cancelTokenSource.token, // Use the cancelToken option
    });

    return response.data;
  } catch (error) {
    if (axios.isCancel(error)) {
      console.log("API request was canceled:", error.message);
    } else {
      const Toast = Swal.mixin({
        toast: true,
        position: "top-start",
        showConfirmButton: false,
        timer: 2000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", Swal.stopTimer);
          toast.addEventListener("mouseleave", Swal.resumeTimer);
        },
      });
      Toast.fire({
        icon: "error",
        title: "Internal Server Error.",
      });
      return [];
    }
  }
};
export const rateImage = async (
  username,
  accountId,
  user_id,
  isSave,
  table2SaveFlag
) => {
  try {
    const response = await axios.post(backendUrl + "/api/rateImage", {
      username: username,
      account_id: accountId,
      user_id,
      isSave,
      table2SaveFlag,
    });
    return response.data;
  } catch (error) {
    console.log(error);
    throw new Error(error);
  }
};
// check user availble
export const checkUserAvailble = async (slug) => {
  try {
    const response = await axios.get(backendUrl + `/getUserDetails/${slug}`);
    return response.data;
  } catch (error) {
    throw new Error(error);
  }
};
export const getUserBatch = async (lastId, limit, isWhere) => {
  try {
    const response = await axios.get(backendUrl + `/getUserBatch`, {
      params: {
        lastId: lastId,
        limit: limit,
        isWhere: isWhere,
      },
    });
    return response.data;
  } catch (error) {
    console.log(error);
    throw new Error(error);
  }
};
export const updateLastIdViewProfile = async (lastId, userId, accountId) => {
  try {
    const response = await axios.post(
      backendUrl + `/updateLastId&ViewedProfile`,
      { lastId, userId, accountId }
    );
    return response.data;
  } catch (error) {
    console.log(error);
  }
};
