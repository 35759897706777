const imageBaseUrl = "https://s3.eu-central-1.wasabisys.com/instaua/images/";
export async function loadImages(data) {
  try {
    const promises = [];
    const cacheName = "imageCache";

    await caches.open(cacheName);

    data.forEach((element) => {
      let accountId = element.user_id;
      for (let index = 0; index < 12; index++) {
        let imageUrl = imageBaseUrl + accountId + "_" + index + ".jpeg";

        const promise = fetch(imageUrl).then((response) => {
          if (!response.ok) {
            return;
          }

          // Store the image in the cache
          return caches.open(cacheName).then((cache) => {
            cache.put(imageUrl, response);
          });
        });

        promises.push(promise);
      }
    });

    await Promise.all(promises);
  } catch (error) {
    console.error("Error preloading images:", error);
  }
}
